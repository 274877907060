var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useI18n } from '@oneaudi/i18n-context';
import { Button, AudiPlatformProvider } from '@audi/audi-ui-react-v2';
import { useServicesContext } from '../../context';
import { useFSCheckoutPostData, fsCheckoutPostRequest } from '../../utils/fsCheckoutCTA';
import { FinanceCheckoutLayer } from './FinanceCheckout/FinanceCheckoutLayer';
export const FinanceCheckoutPopoverButton = ({ buttonInfo, vehicle, showFinancingbuttonInfo, trackButtonClick, variant, buttonSize, scContextId, showAsText, isStrechedButton, }) => {
    const layerManager = useServicesContext().getAdditionalService('gfa:layer-manager');
    const [isApiInprogress, setIsApiInprogress] = useState(false);
    const [fsCheckoutLink, setFsCheckoutLink] = useState(buttonInfo.notFoundPageUrl);
    const [focusLayerNode, setFocusLayerNode] = React.useState();
    const [focusLayer, setFocusLayer] = React.useState();
    const financeCheckoutTranslations = {
        popoverHeading: useI18n({
            defaultMessage: '',
            id: `nemo.ui.sc.fs.checkout.popover.heading`,
        }),
        popoverContent: useI18n({
            defaultMessage: '',
            id: `nemo.ui.sc.fs.checkout.popover.content`,
        }),
        popoverCloseButton: useI18n({
            defaultMessage: '',
            id: `nemo.ui.sc.fs.checkout.popover.close`,
        }),
        popoverContinueButton: useI18n({
            defaultMessage: '',
            id: `nemo.ui.sc.fs.checkout.popover.continue`,
        }),
        popoverLegalText: useI18n({
            defaultMessage: '',
            id: `nemo.ui.sc.fs.checkout.popover.legalText`,
        }),
        popoverLegalTextLink: useI18n({
            defaultMessage: '',
            id: `nemo.ui.sc.fs.checkout.popover.popoverLegalTextLink`,
        }),
    };
    const postDataFSCheckout = typeof document !== 'undefined' &&
        useFSCheckoutPostData(vehicle, buttonInfo.dataProfile, vehicle.countryCode, vehicle.langCode, scContextId);
    const fsOnBtnClick = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsApiInprogress(true);
            const fsLink = yield fsCheckoutPostRequest(buttonInfo.url, postDataFSCheckout);
            setFsCheckoutLink(fsLink);
        }
        catch (_a) {
            setFsCheckoutLink(buttonInfo.notFoundPageUrl);
        }
        setIsApiInprogress(false);
        trackButtonClick(Object.assign(Object.assign({}, buttonInfo), { label: buttonInfo.label, url: '' }), showAsText ? 'text' : 'secondary', buttonInfo.trackingCode, 'click on');
    });
    if (!showFinancingbuttonInfo) {
        return null;
    }
    const focusLayerRef = React.useCallback((node) => {
        setFocusLayerNode(node);
    }, [setFocusLayerNode]);
    const openFocusLayer = () => {
        const layer = layerManager.openFocusLayer(() => React.createElement("div", { ref: focusLayerRef }), {}, {
            size: "A",
            userCloseable: true,
            onClose: () => {
                closeFocusLayer();
            },
        });
        fsOnBtnClick();
        setFocusLayer(layer);
        closeFocusLayer();
    };
    const closeFocusLayer = () => {
        focusLayer.close();
    };
    return (React.createElement(React.Fragment, null,
        focusLayerNode &&
            ReactDOM.createPortal(React.createElement(AudiPlatformProvider, null,
                React.createElement(FinanceCheckoutLayer, { buttonInfo: buttonInfo, trackButtonClick: trackButtonClick, financeCheckoutTranslations: financeCheckoutTranslations, isApiInprogress: isApiInprogress, closeFocusLayer: closeFocusLayer, fsCheckoutLink: fsCheckoutLink })), focusLayerNode),
        React.createElement(StyledButton, { onClick: () => openFocusLayer(), variant: showAsText ? 'text' : variant, size: buttonSize, stretch: isStrechedButton, spaceStackEnd: showAsText ? 'm' : 'xxs', spaceInlineEnd: showAsText ? 'xxl' : 'xxs', "data-tracking-exclude": true, "data-testid": "finance-checkout" }, buttonInfo.label)));
};
const StyledButton = styled((props) => React.createElement(Button, Object.assign({}, props))) `
  @media (max-width: 479px) {
    width: 100%;
  }
`;
