var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useI18n } from '@oneaudi/i18n-context';
import { ACTIONS, SELECTORS } from '@oneaudi/stck-store';
import { AudiPlatformProvider, Button, Text } from '@audi/audi-ui-react-v2';
import { getDefaultProduct, getProducts } from '../services/crs/api';
import { resolveDisclaimer } from '../services/crs/helpers';
import { getFSAGDefaultProduct, getPaymentValue, mergeDeep, patchFormParameter, patchProductData, } from './crs-helper';
import { getActiveCampaign, resetFinancingCampaign } from './campaign-helper';
import { useSvd } from '../../../hooks';
import { useServicesContext } from '../../../context';
import { DangerousElement, FootnoteReference } from '../..';
import { FinanceModal } from './form/FinanceModal';
import { trackLayerOpen, trackLayerClose } from '../../ctaButtons/tracking';
export const CRSFinanceElement = ({ fullVehicle, trackLayerLinkClick, rateTemplate, configureFinanceComponents, }) => {
    var _a, _b, _c;
    const { getConfiguration, getEnvironmentConfig } = useServicesContext();
    const scopesFromConfig = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes;
    const financingDisclaimersTextStyle = scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.interpretDisclaimersTextStyle;
    const showShortDisclaimer = (scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.financeOption) === 'STATIC_WITH_DISCLAIMER';
    const showSpecialShortDisclaimer = (scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.financeOption) === 'FULL';
    const hideChangeRateCTA = (scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.hideRateChangeCTA) && fullVehicle.basic.buyableOnline;
    const ecomAndHideFinanceForEcom = fullVehicle.basic.buyableOnline && (scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.hideFinanceForEcom);
    const carType = ((_b = fullVehicle === null || fullVehicle === void 0 ? void 0 : fullVehicle.basic) === null || _b === void 0 ? void 0 : _b.type) === 'N' ? 'new' : 'used';
    const localeService = useServicesContext().getAdditionalService('gfa:locale-service');
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const layerManager = useServicesContext().getAdditionalService('gfa:layer-manager');
    const country = localeService.countryCode;
    const usePlainPrice = (country === null || country === void 0 ? void 0 : country.toLowerCase()) === 'gb';
    const svd = useSvd();
    const dispatch = useDispatch();
    const i18n = {
        rateAlternativeText: useI18n({ id: 'nemo.ui.sc.details.finance.rate.alternativeText' }),
        disclaimerText: useI18n({
            id: 'nemo.ui.sc.details.finance.disclaimer.text',
            defaultMessage: 'Ex.:',
        }),
        rateText1: `${useI18n({
            id: 'nemo.ui.sc.details.finance.rate.text1',
            defaultMessage: 'or with rate, e.g. monthly',
        })} `,
        rateText2: `${useI18n({
            id: 'nemo.ui.sc.details.finance.rate.text2',
            defaultMessage: 'with',
        })} `,
        rateChange: useI18n({
            id: 'nemo.ui.sc.details.finance.rate.button.change',
            defaultMessage: 'Change rate',
        }),
        errorHeadline: useI18n({
            id: 'nemo.ui.sc.details.finance.error.headline',
        }),
        errorMessage: useI18n({
            id: 'nemo.ui.sc.details.finance.error.text',
        }),
        errorCloseButton: useI18n({
            id: 'nemo.ui.sc.details.finance.error.button.close',
        }),
    };
    const marketPath = React.useMemo(() => { var _a, _b; return (_b = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.scs) === null || _b === void 0 ? void 0 : _b.scsMarketPath; }, [getConfiguration]);
    const currencyPattern = React.useMemo(() => { var _a; return (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.currencyPattern; }, [getConfiguration]);
    const errorInWebcalcRef = useRef(false);
    const [focusLayerNode, setFocusLayerNode] = React.useState();
    const [focusLayer, setFocusLayer] = React.useState();
    const focusLayerRef = React.useCallback((node) => {
        setFocusLayerNode(node);
    }, [setFocusLayerNode]);
    const setCalculationError = (error) => {
        errorInWebcalcRef.current = error;
    };
    const openFocusLayer = () => {
        const layer = layerManager.openFocusLayer(() => React.createElement("div", { ref: focusLayerRef }), {}, {
            size: "A",
            userCloseable: true,
            onClose: () => {
                trackLayerClose(trackingService);
                if (errorInWebcalcRef.current) {
                    loadFinanceData();
                }
            },
        });
        setFocusLayer(layer);
    };
    const closeFocusLayer = () => {
        focusLayer.close();
    };
    const [error, setError] = React.useState('');
    const [alertLayerNode, setAlertLayerNode] = React.useState();
    const [alertLayer, setAlertLayer] = React.useState();
    const alertLayerRef = React.useCallback((node) => {
        setAlertLayerNode(node);
    }, [setAlertLayerNode]);
    const openAlertLayer = () => {
        const layer = layerManager.openAlert(() => React.createElement("div", { ref: alertLayerRef }), {}, {
            userCloseable: true,
            id: 'VTP_dynamic_financing_error',
            onClose: () => {
                setError('');
            },
        });
        setAlertLayer(layer);
    };
    const closeAlertLayer = () => {
        alertLayer.close();
    };
    useEffect(() => {
        if (error) {
            openAlertLayer();
        }
    }, [error]);
    const onError = (errorMessage) => {
        console.error(errorMessage);
        closeFocusLayer();
        if ((!alertLayer || alertLayer.isClosed) && !error) {
            setTimeout(() => {
                setError(errorMessage);
            }, 100);
        }
    };
    const getPaymentDescription = (parameters) => {
        if (campaign &&
            campaign.filterText &&
            (!Object.keys(productParameter).length ||
                (productParameter && productParameter['@CampaignID']))) {
            return campaign.filterText;
        }
        if (typeof (parameters === null || parameters === void 0 ? void 0 : parameters.Description) === 'string') {
            return parameters.Description;
        }
        if (parameters === null || parameters === void 0 ? void 0 : parameters.Description['#text']) {
            return parameters.Description['#text'];
        }
        return '';
    };
    const useSpainTemplate = (marketPath === null || marketPath === void 0 ? void 0 : marketPath.split('/').map((part) => part.toLowerCase()).includes('es')) && (fullVehicle === null || fullVehicle === void 0 ? void 0 : fullVehicle.basic.type) === 'U';
    if (configureFinanceComponents.disableFinancing ||
        !((_c = fullVehicle.detail) === null || _c === void 0 ? void 0 : _c.financingRequest) ||
        ecomAndHideFinanceForEcom) {
        return null;
    }
    let productParameter = {};
    let formParameter = {};
    useSelector((state) => {
        productParameter = SELECTORS.FINANCING.getProductParameterForVehicleId(state, fullVehicle.basic.id);
        formParameter = SELECTORS.FINANCING.getFormParameterForVehicleId(state, fullVehicle.basic.id);
    });
    const [financeData, setFinanceData] = React.useState(null);
    const [financeRequestData, setFinanceRequestData] = React.useState(fullVehicle.detail.financingRequest.Request);
    const [apiErrorInFinance, setApiErrorInFinance] = React.useState(false);
    const [campaign, setCampaign] = React.useState(null);
    const [rate, setRate] = React.useState('');
    const [calculationDisclaimer, setCalculationDisclaimer] = React.useState('');
    const [rateText, setRateText] = React.useState('');
    useSelector((state) => {
        var _a, _b, _c, _d, _e, _f;
        const defaultResponse = SELECTORS.FINANCING.getDefaultDataForVehicleId(state, fullVehicle.basic.id);
        if ((_a = defaultResponse === null || defaultResponse === void 0 ? void 0 : defaultResponse.Response) === null || _a === void 0 ? void 0 : _a.Parameters) {
            if ((_c = (_b = defaultResponse === null || defaultResponse === void 0 ? void 0 : defaultResponse.Response) === null || _b === void 0 ? void 0 : _b.Parameters) === null || _c === void 0 ? void 0 : _c.Group) {
                const group = (_e = (_d = defaultResponse === null || defaultResponse === void 0 ? void 0 : defaultResponse.Response) === null || _d === void 0 ? void 0 : _d.Parameters) === null || _e === void 0 ? void 0 : _e.Group;
                const newRate = Array.isArray(group)
                    ? getPaymentValue(group[0].Parameter, currencyPattern)
                    : getPaymentValue(group.Parameter, currencyPattern);
                if (newRate !== rate) {
                    setRate(newRate);
                }
            }
            const newRateText = getPaymentDescription((_f = defaultResponse === null || defaultResponse === void 0 ? void 0 : defaultResponse.Response) === null || _f === void 0 ? void 0 : _f.Parameters);
            if (newRateText !== rateText) {
                setRateText(newRateText);
            }
            const newCalculationDisclaimer = resolveDisclaimer(defaultResponse === null || defaultResponse === void 0 ? void 0 : defaultResponse.Response, 'CalculationDisclaimer', financingDisclaimersTextStyle);
            if (newCalculationDisclaimer !== calculationDisclaimer) {
                setCalculationDisclaimer(newCalculationDisclaimer);
            }
        }
    });
    const useCampaignAsProduct = () => {
        if (campaign === null) {
            return;
        }
        if (Object.keys(campaign).length &&
            financeRequestData.Product &&
            campaign.campaignId &&
            campaign.typeDetail &&
            (!Object.keys(productParameter).length ||
                (productParameter && productParameter['@CampaignID']))) {
            financeRequestData.Product = {
                '@CampaignID': campaign.campaignId,
                '@ID': campaign.typeDetail,
            };
            setFinanceRequestData(financeRequestData);
            dispatch(ACTIONS.FINANCING.addFinancingForVehicle({
                vehicleId: fullVehicle.basic.id,
                financing: { campaign },
            }));
        }
    };
    useEffect(() => {
        const getFinanceData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield loadFinanceData();
                setCalculationDisclaimer(resolveDisclaimer(financeData, 'CalculationDisclaimer', financingDisclaimersTextStyle) ||
                    '');
            }
            catch (_a) {
                setApiErrorInFinance(true);
            }
        });
        getFinanceData();
    }, []);
    useEffect(() => {
        var _a;
        if (financeData === null || financeData === void 0 ? void 0 : financeData.Parameters) {
            if ((_a = financeData === null || financeData === void 0 ? void 0 : financeData.Parameters) === null || _a === void 0 ? void 0 : _a.Group) {
                const group = financeData.Parameters.Group;
                const newRate = Array.isArray(group)
                    ? getPaymentValue(group[0].Parameter, currencyPattern)
                    : getPaymentValue(group.Parameter, currencyPattern);
                if (newRate !== rate) {
                    setRate(newRate);
                }
            }
            const newRateText = getPaymentDescription(financeData.Parameters);
            if (newRateText !== rateText) {
                setRateText(newRateText);
            }
            const newCalculationDisclaimer = resolveDisclaimer(financeData, 'CalculationDisclaimer', financingDisclaimersTextStyle);
            if (newCalculationDisclaimer !== calculationDisclaimer) {
                setCalculationDisclaimer(newCalculationDisclaimer);
            }
        }
    }, [financeData === null || financeData === void 0 ? void 0 : financeData.Parameters]);
    const loadFinanceData = () => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e, _f, _g;
        const endpointUrl = (_d = getEnvironmentConfig()) === null || _d === void 0 ? void 0 : _d.crsServiceBaseUrl;
        let defaultProduct = Array.isArray(financeRequestData.Product)
            ? financeRequestData.Product[0]
            : financeRequestData.Product;
        if ((_e = fullVehicle.detail.financingProductsRequest) === null || _e === void 0 ? void 0 : _e.Request) {
            const products = yield getProducts(endpointUrl, (_f = fullVehicle.detail.financingProductsRequest) === null || _f === void 0 ? void 0 : _f.Request, fullVehicle.basic.id, dispatch, trackingService, false);
            defaultProduct = getFSAGDefaultProduct(products);
            patchProductData(products.Products.Product, financeRequestData, productParameter, defaultProduct);
            setFinanceRequestData(financeRequestData);
        }
        defaultProduct = patchFormParameter(defaultProduct, formParameter) || defaultProduct;
        const defaultResponse = yield getDefaultProduct(endpointUrl, mergeDeep(financeRequestData, { Product: defaultProduct }), fullVehicle.basic.id, dispatch, trackingService, false);
        const financingStore = {
            defaultResponse: { Response: defaultResponse },
            defaultProductData: defaultProduct,
            vehicleData: {
                default: financeRequestData,
                product: (_g = fullVehicle.detail.financingProductsRequest) === null || _g === void 0 ? void 0 : _g.Request,
            },
        };
        dispatch(ACTIONS.FINANCING.addFinancingForVehicle({
            vehicleId: fullVehicle.basic.id,
            financing: financingStore,
        }));
        setFinanceData(defaultResponse);
    });
    const loadCampaigns = () => __awaiter(void 0, void 0, void 0, function* () {
        const activeCampaign = yield getActiveCampaign(fullVehicle, getEnvironmentConfig(), svd.svd, getConfiguration());
        if (!activeCampaign || !Object.keys(activeCampaign).length) {
            resetFinancingCampaign(dispatch, fullVehicle);
        }
        setCampaign(activeCampaign);
        useCampaignAsProduct();
    });
    useEffect(() => {
        if (!campaign) {
            loadCampaigns();
        }
    }, [campaign]);
    if (!financeData && apiErrorInFinance) {
        return null;
    }
    if (configureFinanceComponents.disableDynamicFinancing || financeData === undefined) {
        return rateTemplate || null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "sc-crs-finance-element" },
            React.createElement("input", { type: "hidden", className: "sc-vehicle-key", "data-vehicle-id": fullVehicle.basic.id, value: String(financeRequestData.Vehicle.Key) }),
            showShortDisclaimer && (React.createElement(DisclaimerText, { variant: "copy2", id: "short-disclaimer" },
                i18n.disclaimerText,
                calculationDisclaimer)),
            !showShortDisclaimer && !useSpainTemplate && (React.createElement(React.Fragment, null,
                React.createElement(FinanceRateText, { variant: "copy1", as: "span", "data-testid": "financeRateText", spaceStackEnd: "m", spaceStackStart: "xxs" },
                    i18n.rateText1,
                    React.createElement(Text, { as: "span", weight: usePlainPrice ? 'normal' : 'bold', variant: "copy1" }, rate),
                    React.createElement(FootnoteReference, { refId: `fn_rate_stockcar_${carType}` }),
                    rateText && rateText !== '' && (React.createElement(React.Fragment, null,
                        ` ${i18n.rateText2}`,
                        React.createElement(Text, { as: "span", variant: "copy1", className: "sc-finance-product" }, rateText)))))),
            !showShortDisclaimer && useSpainTemplate && (React.createElement(DetailRateSpain, null,
                React.createElement(Text, { variant: "copy1", as: "span", className: "sc-detail-rate--spain" }, i18n.rateText1),
                React.createElement(Text, { variant: "copy1", as: "span", className: "sc-finance-rate", weight: "bold" }, rate),
                React.createElement(Text, { variant: "copy1", as: "span" }, ` ${i18n.rateText2}`))),
            !showShortDisclaimer && (React.createElement(React.Fragment, null,
                showSpecialShortDisclaimer && calculationDisclaimer !== '' && (React.createElement(DisclaimerText, { variant: "copy1", spaceStackStart: "m", spaceStackEnd: "m" },
                    React.createElement(DangerousElement, { as: "span", html: calculationDisclaimer }))),
                hideChangeRateCTA && i18n.rateAlternativeText && (React.createElement(Text, { variant: "copy1", as: "span", className: "sc-detail-rate audi-copy-m" }, i18n.rateAlternativeText)),
                !hideChangeRateCTA && financeData && (React.createElement(Button, { id: "change-rate-text", variant: "text", "data-testid": "change-rate-text", spaceStackEnd: "m", onClick: (event) => {
                        openFocusLayer();
                        trackLayerLinkClick(event, i18n.rateChange);
                        trackLayerOpen(trackingService, 'vtp finance', 'vtp finance layer');
                    }, "data-tracking-exclude": true },
                    React.createElement(Text, { variant: "copy1", as: "span" },
                        React.createElement(DangerousElement, { html: i18n.rateChange }))))))),
        focusLayerNode &&
            ReactDOM.createPortal(React.createElement(AudiPlatformProvider, null,
                React.createElement(FinanceModal, { fullVehicle: fullVehicle, onClose: closeFocusLayer, onError: onError, setCalculationError: setCalculationError, configureFinanceComponents: configureFinanceComponents })), focusLayerNode),
        alertLayerNode &&
            ReactDOM.createPortal(React.createElement(AudiPlatformProvider, null,
                React.createElement(AlertModal, null,
                    React.createElement(Text, { variant: "order2", as: "h2" }, i18n.errorHeadline),
                    React.createElement(Text, { variant: "copy1", as: "p", spaceStackStart: "m" }, i18n.errorMessage ? i18n.errorMessage : error),
                    React.createElement(Button, { variant: "primary", onClick: closeAlertLayer, spaceStackStart: "m" }, i18n.errorCloseButton))), alertLayerNode)));
};
const DisclaimerText = styled((props) => React.createElement(Text, Object.assign({}, props))) `
  color: var(${(props) => props.theme.colors.base.grey[60]});
`;
const AlertModal = styled.div `
  text-align: center;
`;
const DetailRateSpain = styled.div `
  display: block;
  margin-bottom: var(${(props) => props.theme.responsive.spacing.m});
  margin-top: var(${(props) => props.theme.responsive.spacing.xs});
`;
const FinanceRateText = styled((props) => React.createElement(Text, Object.assign({}, props))) `
  display: block;
  margin-top: var(${(props) => props.theme.responsive.spacing.xs});
  margin-bottom: var(${(props) => props.theme.responsive.spacing.s});
  color: var(${(props) => props.theme.colors.base.grey[80]});
  font-family: var(${(props) => props.theme.fontFamily});
  font-stretch: var(${(props) => props.theme.responsive.typography.copy1.fontStretch});
  -webkit-font-smoothing: antialiased;
`;
