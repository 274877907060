import { useServicesContext } from '../../context';
export var PatternType;
(function (PatternType) {
    PatternType["CURRENCY_PRICE"] = "{{currency}}{{price}}";
    PatternType["PRICE_CURRENCY"] = "{{price}}{{currency}}";
    PatternType["CURRENCY_TEXT_PRICE"] = "{{currency_text}}{{price}}";
    PatternType["PRICE_CURRENCY_TEXT"] = "{{price}}{{currency_text}}";
})(PatternType || (PatternType = {}));
export const useFormattedPrice = (value, unit, textUnit) => {
    var _a, _b;
    const { audiMarketContextService, getConfiguration } = useServicesContext();
    const pattern = (_b = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.currencyPattern) !== null && _b !== void 0 ? _b : audiMarketContextService === null || audiMarketContextService === void 0 ? void 0 : audiMarketContextService.getContextItem('stockcar.custom.currency.pattern');
    const displayUnit = unit || textUnit || '';
    const textUnitDisplay = textUnit || unit || '';
    if (value && pattern) {
        switch (pattern) {
            case PatternType.CURRENCY_PRICE:
                return `${displayUnit} ${value}`;
            case PatternType.PRICE_CURRENCY:
                return `${value} ${displayUnit}`;
            case PatternType.CURRENCY_TEXT_PRICE:
                return `${textUnitDisplay} ${value}`;
            case PatternType.PRICE_CURRENCY_TEXT:
                return `${value} ${textUnitDisplay}`;
            default:
                return `${value} ${displayUnit}`;
        }
    }
    else {
        return '';
    }
};
