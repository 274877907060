import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { addFootnoteReference, removeFootnoteReference } from '../state/actions';
import { useFootnoteReferenceDispatch, useFootnoteReferenceState } from '../..';
const FootnoteSpan = styled.span `
  font-weight: var(${(props) => props.theme.fontWeights.bold});
`;
const FootnoteLink = styled.a `
  border-bottom: 0 !important;
  line-height: 1;
  text-decoration: none !important;
  font-weight: inherit !important;
  color: inherit !important;
  font-size: var(${(props) => props.theme.responsive.typography.copy1.fontSize}) !important;
  vertical-align: text-top;
`;
export const FootnoteLinkSup = styled.sup `
  vertical-align: super;
  top: 0;
`;
export const FootnoteReference = ({ refId, useSpan }) => {
    const { references } = useFootnoteReferenceState();
    const dispatch = useFootnoteReferenceDispatch();
    const [referenceNumber, setReferenceNumber] = useState(null);
    const [referenceContextId, setReferenceContextId] = useState('');
    useEffect(() => {
        dispatch(addFootnoteReference(refId));
        return () => {
            dispatch(removeFootnoteReference(refId));
        };
    }, [dispatch, refId]);
    useEffect(() => {
        const reference = references.find((ref) => ref.id === refId);
        const refNumber = reference === null || reference === void 0 ? void 0 : reference.number;
        const contextId = reference === null || reference === void 0 ? void 0 : reference.contextId;
        setReferenceContextId(contextId);
        if (refNumber && refNumber > 0) {
            setReferenceNumber(refNumber);
        }
    }, [refId, references]);
    const scrollToRef = (evt) => {
        var _a;
        evt.preventDefault();
        (_a = document.querySelector(`#${referenceContextId}`)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
    };
    if (useSpan && referenceNumber) {
        return (React.createElement("span", { translate: "no" },
            React.createElement(FootnoteSpan, { className: "audi-j-footnote-reference", onClick: scrollToRef },
                React.createElement(FootnoteLinkSup, { className: "audi-footnote-anchor__text" }, referenceNumber))));
    }
    return (React.createElement("span", { translate: "no" }, referenceNumber && (React.createElement(FootnoteLink, { className: "audi-j-footnote-reference", href: `#${referenceContextId || refId}`, onClick: scrollToRef },
        React.createElement(FootnoteLinkSup, { className: "audi-footnote-anchor__text" }, referenceNumber)))));
};
