var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ACTIONS, SELECTORS } from '@oneaudi/stck-store';
import { generateRequestParamString, httpRequest } from '../utils';
export function loadFilteredResults(configSource, envConfig, storeInstance, dispatch, context, svd) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const filters = SELECTORS.FILTERS.getSelectedFiltersMap(storeInstance.state);
        const presets = SELECTORS.FILTERS.getPersistedPresetsMap(storeInstance.state);
        const resultIndex = SELECTORS.UI.getResultIndex(storeInstance.state);
        const sorting = SELECTORS.SORTING.getSortingStateRaw(storeInstance.state);
        const requestParam = generateFilterRequestParam(filters, presets);
        requestParam.set('from', resultIndex + 12);
        requestParam.set('size', 12);
        requestParam.set('sort', sorting[context]);
        requestParam.set('svd', svd || storeInstance.state.svdVersion);
        resetFilterSortingIfNecessary(requestParam, dispatch);
        requestParam.set('sort', SELECTORS.SORTING.getSortingStateRaw(storeInstance.state)[context]);
        dispatch(ACTIONS.UI.incrementResultsCount());
        const response = yield fetchResults((_a = envConfig === null || envConfig === void 0 ? void 0 : envConfig.scs) === null || _a === void 0 ? void 0 : _a.baseUrl, (_c = (_b = configSource.vtpConfiguration) === null || _b === void 0 ? void 0 : _b.scs) === null || _c === void 0 ? void 0 : _c.scsMarketPath, requestParam);
        dispatch(ACTIONS.VEHICLES.handleIncrementalResponse({ response }));
    });
}
export function fetchResults(scsBaseUrl, scsMarketPath, requestParams = new Map()) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = generateRequestParamString(`${scsBaseUrl}v2/search/filter/${scsMarketPath}`, requestParams);
        const response = yield httpRequest(url);
        return response.parsedBody;
    });
}
export function resetFilterSortingIfNecessary(requestParams, dispatch) {
    var _a, _b, _c;
    const isDistanceSortingActive = (_a = requestParams.get('sort')) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('distance');
    const isGeoFilterActive = ((_b = requestParams.get('filter')) === null || _b === void 0 ? void 0 : _b.includes('geo:')) || ((_c = requestParams.get('preset')) === null || _c === void 0 ? void 0 : _c.includes('geo:'));
    if (isDistanceSortingActive && !isGeoFilterActive) {
        dispatch(ACTIONS.SORTING.resetSorting());
    }
}
export function generateFilterRequestParam(activeFilterItems_ = new Map(), presetFiltersMap = new Map()) {
    const filterParams = [...activeFilterItems_.values()].map((filterDTO) => filterDTO.toString());
    const presetFilterParams = [...presetFiltersMap.values()].map((filterDTO) => filterDTO.toString());
    const filterRequestParam = new Map();
    if (presetFilterParams.length) {
        filterRequestParam.set('preset', presetFilterParams.join(','));
    }
    if (filterParams.length) {
        filterRequestParam.set('filter', filterParams.join(','));
    }
    return filterRequestParam;
}
