import * as React from 'react';
import { Text } from '@audi/audi-ui-react-v2';
import { FootnoteReference } from '../footnotes/components/FootnoteReference';
import { useAvailableSoonLabels } from '../../hooks';
import { isAvailableSoonVehicle } from '../../utils';
import { useServicesContext } from '../../context';
export const AvailableSoon = ({ vehicle, useAvailableNowText }) => {
    var _a, _b, _c, _d;
    const { getConfiguration } = useServicesContext();
    const showAvailableFromDateIndependentlyFromData = (_b = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes) === null || _b === void 0 ? void 0 : _b.showAvailableSoonDate;
    const availableSoonVehicle = isAvailableSoonVehicle(vehicle, showAvailableFromDateIndependentlyFromData);
    const { expectedLabel, availableFromDateOrLabel } = useAvailableSoonLabels(vehicle, useAvailableNowText);
    const carType = vehicle.type === 'N' ? 'new' : 'used';
    const isAvailableFromCodeNow = (vehicle === null || vehicle === void 0 ? void 0 : vehicle.availableFromCode) === 'now';
    const isAvailableFromCodeSoon = (vehicle === null || vehicle === void 0 ? void 0 : vehicle.availableFromCode) === 'soon';
    const hasSpecialDealerFootnote = (vehicle === null || vehicle === void 0 ? void 0 : vehicle.availableFromCode) === 'date' && ((_c = vehicle === null || vehicle === void 0 ? void 0 : vehicle.dealer) === null || _c === void 0 ? void 0 : _c.id) === '03611';
    const isAvailableFromCodeDate = (vehicle === null || vehicle === void 0 ? void 0 : vehicle.availableFromCode) === 'date' && ((_d = vehicle === null || vehicle === void 0 ? void 0 : vehicle.dealer) === null || _d === void 0 ? void 0 : _d.id) !== '03611';
    return (React.createElement(React.Fragment, null, (availableSoonVehicle || useAvailableNowText) && (React.createElement(React.Fragment, null,
        React.createElement(Text, { variant: "copy2", as: "p", spaceStackEnd: "xs", "data-testid": "expectedFromLabel" },
            expectedLabel,
            isAvailableFromCodeSoon && (React.createElement(React.Fragment, null,
                React.createElement(FootnoteReference, { refId: `fn_available_soon_stockcar_${carType}` }))),
            hasSpecialDealerFootnote && (React.createElement(React.Fragment, null,
                React.createElement(FootnoteReference, { refId: "fn_availablefromdate" }),
                React.createElement(FootnoteReference, { refId: "fn_available_date_stockcar_special" }))),
            isAvailableFromCodeDate && (React.createElement(React.Fragment, null,
                React.createElement(FootnoteReference, { refId: "fn_availablefromdate" }),
                React.createElement(FootnoteReference, { refId: `fn_available_date_stockcar_${carType}` }))),
            isAvailableFromCodeNow && (React.createElement(React.Fragment, null,
                React.createElement(FootnoteReference, { refId: "fn_available_now" }),
                React.createElement(FootnoteReference, { refId: `fn_available_now_stockcar_${carType}` }))),
            (expectedLabel === null || expectedLabel === void 0 ? void 0 : expectedLabel.trim()) !== '' ? ':\u00A0' : ''),
        React.createElement(Text, { variant: "copy1", weight: "bold", spaceStackEnd: "m" }, availableFromDateOrLabel)))));
};
