import { generatePdpUrl } from './vehicle';
export const formattedDate = (timestamp) => {
    if (!timestamp)
        return '';
    try {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        return `${year}-${month}-${day}`;
    }
    catch (_a) {
        return '';
    }
};
export const replacePricesInUrl = (url, typedPrices) => {
    if (!typedPrices || typedPrices.length === 0)
        return url;
    let result = url;
    typedPrices.forEach((price) => {
        const pattern = `{{sc_${price.type}}}`;
        const replaceValue = `${price.amount}`;
        result = result.replace(pattern, replaceValue);
    });
    return result;
};
export function formatUrl(pattern, vehicle) {
    var _a, _b, _c;
    let url = pattern;
    url = url.replace('{{sc_vehicle_id}}', vehicle.id);
    const seoUrl = generatePdpUrl(vehicle.modelYear, ((_a = vehicle.model) === null || _a === void 0 ? void 0 : _a.description) || vehicle.symbolicCarline.description, vehicle.id);
    url = url.replace('{{sc_vehicle_url}}', seoUrl);
    url = url.replace('{{sc_car_id}}', vehicle.carId);
    url = url.replace('{{sc_audicode}}', vehicle.audiCode);
    url = url.replace('{{sc_dealer_id}}', vehicle.dealer.id);
    url = url.replace('{{sc_dealer_name}}', vehicle.dealer.name);
    url = url.replace('{{sc_raw_dealer_id}}', vehicle.rawDealerId);
    url = url.replace('{{sc_commission_number}}', vehicle.commissionNumber);
    url = url.replace('{{sc_market_reference}}', `${vehicle.marketReference}`);
    url = url.replace('{{sc_model_code}}', vehicle.modelCode.code);
    url = url.replace('{{sc_carline}}', vehicle.symbolicCarline.code);
    url = url.replace('{{sc_carline_description}}', encodeURIComponent(vehicle.symbolicCarline.description));
    url = url.replace('{{sc_entry_url}}', encodeURIComponent(vehicle.entryUrl));
    url = url.replace('{{sc_model_year}}', `${vehicle.modelYear}`);
    url = url.replace('{{sc_vin}}', `${vehicle.vin}`);
    url = url.replace('{{sc_stock_keeping_unit}}', vehicle.stockkeepingunit);
    if (vehicle.financeLoan) {
        url = url.replace('{{sc_loan_type}}', `${vehicle.financeLoan.info}`);
    }
    if (vehicle.used) {
        url = url.replace('{{sc_mileage}}', `${vehicle.used.mileage}`);
        if (vehicle.used.initialRegistrationDate) {
            const { initialRegistrationDate } = vehicle.used;
            url = url.replace('{{sc_initialregistration_month}}', String(new Date(initialRegistrationDate).getMonth() + 1));
            url = url.replace('{{sc_initialregistration_year}}', String(new Date(initialRegistrationDate).getFullYear()));
            const formatted = formattedDate(initialRegistrationDate);
            url = url.replace('{{sc_initial_registration_date}}', formatted);
        }
    }
    if (vehicle === null || vehicle === void 0 ? void 0 : vehicle.preused) {
        url = url.replace('{{sc_preused}}', vehicle === null || vehicle === void 0 ? void 0 : vehicle.preused);
    }
    const retailPrice = (_b = vehicle.typedPrices) === null || _b === void 0 ? void 0 : _b.find((price) => price.type === 'retail');
    if (retailPrice) {
        url = url.replace('{{sc_price}}', `${retailPrice.amount}`);
    }
    const regularPrice = (_c = vehicle.typedPrices) === null || _c === void 0 ? void 0 : _c.find((price) => price.type === 'regular');
    if (regularPrice) {
        url = url.replace('{{sc_list_price}}', `${regularPrice.amount}`);
    }
    url = replacePricesInUrl(url, vehicle.typedPrices);
    if (typeof window !== 'undefined') {
        try {
            const lsUrlOrigin = JSON.parse(window.localStorage.getItem('url_origin'));
            const lsUrlSearch = JSON.parse(window.localStorage.getItem('url_search'));
            url = url.replace('{{sc_url_origin}}', (lsUrlOrigin === null || lsUrlOrigin === void 0 ? void 0 : lsUrlOrigin.value) || '');
            url = url.replace('{{sc_url_search}}', (lsUrlSearch === null || lsUrlSearch === void 0 ? void 0 : lsUrlSearch.value) || '');
            url = url.replace('{{sc_location}}', window.location.href);
        }
        catch (err) {
            console.log('Cannot parse pattern from localStorage', err);
        }
    }
    return url;
}
