var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Layout, Loader, Text } from '@audi/audi-ui-react-v2';
import { FinanceLayerParameter } from './FinanceLayerParameter';
import { FinanceDisclaimerMessages } from './FinanceDisclaimerMessages';
import { CoreCiIcon } from '../..';
import { useBodyScrollLock, useVehicleRaw } from '../../../hooks';
import { useServicesContext } from '../../../context';
import { getDefaultProduct } from '../services/crs/api';
import { mergeDeep } from '../dynamic-finance/crs-helper';
import { mergeFSAGProduct } from '../services/crs/helpers';
const RateInfoLayer = styled.div `
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNkYGDYz0AEYCJG0ahC6ikEAECXANNoGlngAAAAAElFTkSuQmCC');
  height: 100%;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  text-align: left;
`;
const RateInfoLayerWrapper = styled.div `
  max-width: 1080px;
  @media (min-width: 768px) {
    margin: 0 auto;
    width: 76%;
  }
`;
const RateInfoLayerContent = styled.div `
  background-color: #fff;
  padding: 42px 4%;

  @media (min-width: 768px) {
    padding: 48px 8% 48px 4%;
  }

  @media all and (min-width: 1024px) {
    padding-bottom: 60px;
    padding-top: 60px;
  }

  @media all and (min-width: 1440px) {
    padding-bottom: 78px;
    padding-top: 78px;
  }

  @media all and (min-width: 1920px) {
    padding-bottom: 78px;
    padding-top: 78px;
  }
`;
const ParameterTable = styled.div `
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(${(props) => props.theme.responsive.spacing.xl});
  max-width: 1280px;
`;
const CloseButton = styled.div `
  cursor: pointer;
  float: right;
  height: 48px;
  margin-right: -4%;
  margin-top: -10px;
  width: 48px;
`;
const Icon = styled.div ``;
export const FinanceLayer = ({ vehicleId, setShowLayer, trackFinInfoLayerClose, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    useBodyScrollLock();
    const { getConfiguration, getEnvironmentConfig } = useServicesContext();
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const [financing, setFinancing] = React.useState(undefined);
    const dispatch = useDispatch();
    const { vehicleRaw: fullVehicle } = useVehicleRaw(vehicleId);
    const hideCalculationDisclaimer = (_b = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes) === null || _b === void 0 ? void 0 : _b.hideCalculationDisclaimer;
    const interpretDisclaimersTextStyle = (_d = (_c = getConfiguration()) === null || _c === void 0 ? void 0 : _c.scopes) === null || _d === void 0 ? void 0 : _d.interpretDisclaimersTextStyle;
    const hideFinancingTable = (_f = (_e = getConfiguration()) === null || _e === void 0 ? void 0 : _e.financeLayer) === null || _f === void 0 ? void 0 : _f.hideFinanceTable;
    const disclaimerListItems = (_h = (_g = getConfiguration()) === null || _g === void 0 ? void 0 : _g.financeLayer) === null || _h === void 0 ? void 0 : _h.disclaimerListItems;
    useEffect(() => {
        const getFinanceData = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            try {
                const endpointUrl = (_a = getEnvironmentConfig()) === null || _a === void 0 ? void 0 : _a.crsServiceBaseUrl;
                const defaultProductResponse = yield getDefaultProduct(endpointUrl, mergeDeep(fullVehicle.detail.financingRequest.Request, fullVehicle.basic.financing
                    ? {
                        Product: fullVehicle.basic.financing.product,
                    }
                    : undefined), fullVehicle.basic.id, dispatch, trackingService);
                const newFinancing = mergeFSAGProduct(financing, defaultProductResponse, interpretDisclaimersTextStyle);
                setFinancing(newFinancing);
            }
            catch (e) {
                console.error('An error occcured while fetching finnce data', e);
            }
        });
        if (fullVehicle) {
            getFinanceData();
        }
    }, [fullVehicle]);
    return (React.createElement(RateInfoLayer, null,
        React.createElement(RateInfoLayerWrapper, null,
            React.createElement(RateInfoLayerContent, null,
                React.createElement(CloseButton, { "data-testid": "CloseButton", onClick: () => {
                        setShowLayer(false);
                        if (trackFinInfoLayerClose !== undefined) {
                            trackFinInfoLayerClose();
                        }
                    } },
                    React.createElement(Icon, null,
                        React.createElement(CoreCiIcon, { icon: "cancel-newci-large", size: "large" }))),
                financing ? (React.createElement(React.Fragment, null,
                    !hideFinancingTable && (React.createElement(Text, { as: "h1", variant: "order2", weight: "normal", spaceStackEnd: "l" }, (_j = financing === null || financing === void 0 ? void 0 : financing.product) === null || _j === void 0 ? void 0 : _j.label)),
                    disclaimerListItems && disclaimerListItems.length > 0 && (React.createElement(FinanceDisclaimerMessages, { financing: financing, hideFinancingTable: hideFinancingTable, hideCalculationDisclaimer: hideCalculationDisclaimer, interpretDisclaimersTextStyle: interpretDisclaimersTextStyle, position: "Top", disclaimerListItems: disclaimerListItems })),
                    !hideFinancingTable && (React.createElement("div", null,
                        React.createElement(ParameterTable, null, financing === null || financing === void 0 ? void 0 : financing.parameters.map((parameter) => (React.createElement(FinanceLayerParameter, { key: parameter.id, parameter: parameter })))))),
                    disclaimerListItems && disclaimerListItems.length > 0 && (React.createElement(FinanceDisclaimerMessages, { financing: financing, hideFinancingTable: hideFinancingTable, hideCalculationDisclaimer: hideCalculationDisclaimer, interpretDisclaimersTextStyle: interpretDisclaimersTextStyle, position: "Bottom", disclaimerListItems: disclaimerListItems })))) : (React.createElement(Layout, { direction: "column", align: "center", spaceStackStart: "xxl", spaceStackEnd: "xxl" },
                    React.createElement(Loader, null)))))));
};
